import React, { useState, useCallback, useEffect } from 'react';
import { Calendar, dayjsLocalizer, Views } from 'react-big-calendar';
import dayjs from 'dayjs';
import Dialog from '@mui/material/Dialog/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import PaymentPlatformForm from '../PaymentPlatformForm';
import { collection, getDocs, doc, addDoc } from 'firebase/firestore';
import { db, query, where } from "../../../firebase_setup/firebase";
import ToastMSG, { ToastErrMSG, ToastAlertMSG } from '../Toast'
// import {Nuki} from 'nuki-web-api'


var dateNow
var timeReservationsLastState = React.createRef();
var zoneRef = React.createRef();
var activityRef = React.createRef();
var accessCodes = React.createRef();
var reservationsRef = React.createRef();
var pendingReservationsRef = React.createRef();
var selectedReservation = React.createRef();
var selectedDate = React.createRef();

timeReservationsLastState.current = []
accessCodes.current = []


const TimeCalendar = (props) => {
  const localizer = dayjsLocalizer(dayjs);
  const [open, setOpen] = React.useState(false);
  const [openReservationInfo, setRInfo] = React.useState(false);

  const [timeReservations, setTimeReservations] = useState(timeReservationsLastState.current);
  const [calendarTime, setTime] = useState([]);
  const [min, setMin] = useState(new Date());
  const [max, setMax] = useState(new Date());
  const [deleteBtn, setDeleteBtn] = useState();
  const [updated, setUpdated] = useState(false);
  const [payment, setPayment] = useState(false);
  const [paymentErr, setPaymentErr] = useState(false);
  const [pendingId, setPendingId] = useState();


  zoneRef.current = props.zoneRef
  activityRef.current = props.activityRef
  reservationsRef.current = props.rs
  pendingReservationsRef.current = props.pendingReservations
  selectedDate.current = props.selectedDate
  // console.clear()

  dateNow = new Date()

  useEffect(() => {
    // console.log(props)
    setCalendarConfig()
  }, [props]);




  const slotPropGetter = date => {
    // Personaliza las propiedades de la casilla de tiempo aquí
    if (activityRef.current.Duration < 20) {
      // Por ejemplo, resalta las casillas de tiempo a las 9 AM
      return {
        style: {
          minHeight: '22vh'
        },
        className: 'custom-slot',
      };
    }
    if (activityRef.current.Duration > 20 && activityRef.current.Duration < 30) {
      // Por ejemplo, resalta las casillas de tiempo a las 9 AM
      return {
        style: {
          minHeight: '11vh'
        },
        className: 'custom-slot',
      };
    }
    if (activityRef.current.Duration >= 30 && activityRef.current.Duration < 45) {
      // Por ejemplo, resalta las casillas de tiempo a las 9 AM
      return {
        style: {
          minHeight: '10vh'
        },
        className: 'custom-slot',
      };
    }
    return {};
  };

  // console.log(props.calendar)
  // console.log(props.rs)

  function setCalendarConfig() {
    var reservationArray = []

    //props Contiene la fecha actual, el calendario recogido de BD y las reservas
    if (props.calendar != undefined) {
      var min = getDateFromTimeStamp(props.calendar.StartTime.seconds, props.calendar.StartTime.nanoseconds)
      var max = getDateFromTimeStamp(props.calendar.EndTime.seconds, props.calendar.EndTime.nanoseconds)
      //Seteamos la hora de inicio y de fin del calendario.

      if (new Date(selectedDate.current).getDate() == new Date().getDate() &&
        new Date(selectedDate.current).getMonth() == new Date().getMonth() &&
        new Date(selectedDate.current).getFullYear() == new Date().getFullYear() &&
        min.getTime() < dateNow.getTime()) {
        dateNow.setMinutes(0)
        dateNow.setSeconds(0)
        if (dateNow.getDate() >= max.getDate() &&
          dateNow.getMonth() >= max.getMonth() &&
          dateNow.getFullYear() >= max.getFullYear() &&
          dateNow.getHours() >= max.getHours() &&
          dateNow.getMinutes() >= max.getMinutes()) {
          setMin(max)
        } else {
          setMin(dateNow)
        }
        //min = dateNow


      } else {
        dateNow = new Date(min)
        setMin(min)

      }


      setMax(max)
    }
    if (props.calendar != undefined && props.calendar.UnabledSlots != undefined) {
      if (props.calendar.UnabledSlots.length > 0) {
        //Después de comprobar si tenemos datos recorremos las horas de cierre ("UnabledSlots")
        props.calendar.UnabledSlots.forEach(element => {
          //Formateamos los TimeStamp de UnabledSlots
          var startTime = getDateFromTimeStamp(element.StartTime.seconds, element.StartTime.nanoseconds);
          var endTime = getDateFromTimeStamp(element.EndTime.seconds, element.EndTime.nanoseconds);

          //Tomamos la fecha actual
          var currentM = new Date()
          //Propagamos la hora de cierre por todo el mes
          for (let i = 0; i <= 29; i++) {

            //Para ello partiendo del día actual añadimos días conforme al bucle y seteamos las horas bloqueadas que tenemos en UnabledSlots
            var currentStartTime = new Date(currentM.getFullYear(), currentM.getMonth(), currentM.getDate() + i);
            currentStartTime.setHours(startTime.getHours());
            currentStartTime.setMinutes(startTime.getMinutes());
            currentStartTime.setSeconds(0);
            currentStartTime.setMilliseconds(0);


            var currentEndTime = new Date(currentM.getFullYear(), currentM.getMonth(), currentM.getDate() + i);
            currentEndTime.setHours(endTime.getHours());
            currentEndTime.setMinutes(endTime.getMinutes());
            currentEndTime.setSeconds(0);
            currentEndTime.setMilliseconds(0);


            //Lo seteamos a modo reserva en la lista de eventos
            reservationArray.push({ start: currentStartTime, end: currentEndTime, title: element.Why, id: "Block" })
          }

        });
      }
      if (props.rs != undefined && props.rs.length > 0) {
        props.rs.forEach(reserve => {
          if (reserve.Zone.id == zoneRef.current.id && reserve.Activity.id == activityRef.current.id) {
            var startTime = getDateFromTimeStamp(reserve.StartDay.seconds, reserve.StartDay.nanoseconds);
            startTime.setSeconds(0); startTime.setMilliseconds(0)
            var endTime = getDateFromTimeStamp(reserve.EndDay.seconds, reserve.EndDay.nanoseconds);
            startTime.setSeconds(0); endTime.setMilliseconds(0)
            if (reserve.UserMail == sessionStorage.getItem("userMail")) {
              reservationArray.push({ start: startTime, end: endTime, title: "", id: reserve.id })
            } else {
              reservationArray.push({ start: startTime, end: endTime, title: "", id: "Block" })
            }
          }
        })
      }
      //ReservationsArray contendra todos los huecos que no se deben mostrar además de las reservas del usuario
      var card = []
      for (let i = 0; i <= 29; i++) {
        var freeTime = max.getHours() - dateNow.getHours()
        var duration = Math.trunc(freeTime / (activityRef.current.Duration / 60))
        var startDate = new Date()
        startDate.setHours(dateNow.getHours());
        startDate.setMinutes(dateNow.getMinutes());
        startDate.setSeconds(dateNow.getSeconds());
        startDate.setMilliseconds(0)
        startDate.setDate(startDate.getDate() + i);

        var startHours = new Date(startDate)
        var endHours = new Date(startDate)
        //Math.trunc(activityRef.current.Duration)
        for (let i = 0; i < duration; i++) {
          startHours = new Date(endHours)
          startHours.setSeconds(0); startHours.setMilliseconds(0)
          // Sumamos los minutos de duración
          endHours = new Date(endHours.getTime() + activityRef.current.Duration * 60000)
          endHours.setSeconds(0); endHours.setMilliseconds(0)
          // Seteamos los parámetros

          var reserve = reservationArray.find(r =>
            r.start.getTime() < endHours.getTime() &&
            r.end.getTime() > startHours.getTime()
          )

          if (startHours.getHours() + 0.1 >= dateNow.getHours() && !(startHours <= new Date() && endHours >= new Date()))
            if (reserve == undefined) {
              // Libre
              card.push({ start: new Date(startHours), end: new Date(endHours), title: "", id: "Free", hexColor: "ebecffc1" })
            } else if (reserve.id != "Block") {
              // Tus reservas
              card.push({ start: new Date(startHours), end: new Date(endHours), title: "", id: reserve.id, hexColor: "3174ad" })
            }

        }


      }
      setTimeReservations(card)
      timeReservationsLastState.current = card
    }


  }

  function getDateFromTimeStamp(seconds, nanoseconds) {
    return new Date((seconds * 1000) + nanoseconds / 1000000)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickRInfoOpen = () => {
    setRInfo(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickRInfoClose = () => {
    setRInfo(false);
  };
  const handleSave = () => {
    handleClose()
    const start = calendarTime[0]
    const end = calendarTime[1]
    if (activityRef.current.Price != 0) {
      createPendingReservation(start, end)
    } else {
      delay(10).then(() => createReservation(start, end))

    }
  };

  function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }
  const handleSelectEvent = useCallback(
    ({ start, end, id }) => {
      if (id == "Free") {
        setTime([start, end])
        selectedReservation.current = {
          Activity: activityRef.current.Name,
          EndDay: end,
          Payed: true,
          Price: activityRef.current.Price,
          ReservationDay: new Date(),
          StartDay: start,
          UserMail: sessionStorage.getItem("userMail"),
          Zone: zoneRef.current.Name
        }
        handleClickOpen()
      } else if (id != "Block" && id != undefined) {
        var reserve = reservationsRef.current.find(r => r.id == id)
        if (reserve != undefined && reserve.StartDay.seconds) {
          reserve.StartDay = getDateFromTimeStamp(reserve.StartDay.seconds, reserve.StartDay.nanoseconds)
          reserve.EndDay = getDateFromTimeStamp(reserve.EndDay.seconds, reserve.EndDay.nanoseconds)
          reserve.ReservationDay = getDateFromTimeStamp(reserve.ReservationDay.seconds, reserve.ReservationDay.nanoseconds)
        }


        selectedReservation.current = reserve
        handleClickRInfoOpen()
      }

    },
    [setTimeReservations]
  )
  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      setTime([start, end])
      handleClickOpen()

    },
    [setTimeReservations]
  )

  const createReservation = async (start, end) => {
    if (reservationsRef.current.find(r =>
      getDateFromTimeStamp(r.StartDay.seconds, r.StartDay.nanoseconds) == start &&
      getDateFromTimeStamp(r.EndDay.seconds, r.EndDay.nanoseconds) == end &&
      r.Activity.id == activityRef.id &&
      r.Zone.id == zoneRef.current.id) == null &&
      timeReservationsLastState.current.find(r => r.start == start && r.end == end && r.id != "Block") != null) {
        
      var code = await getAccessCode(start);

      await addDoc(collection(db, "Reservations"), {
        AccessCode: code,
        Activity: doc(db, "Activities", activityRef.current.id),
        EndDay: end,
        Payed: false,
        Price: activityRef.current.Price,
        ReservationDay: new Date(),
        StartDay: start,
        UserMail: sessionStorage.getItem("userMail"),
        Zone: doc(db, "Zones", zoneRef.current.id)
      }).then(function () {
        let lockID
        let userId
        
        if(process.env.LOCKID != undefined){
          lockID = process.env.LOCKID;
        }else{
          lockID = 18171075572;
        }
        if(process.env.USERID != undefined){
          userId = process.env.USERID;
        }else{
          userId = 1818656268;
        }
       
       
      });

      props.refreshCalendar()
      setUpdated(true)

    } else {
      setPaymentErr(true);
    }

  }
  const createPendingReservation = async (start, end) => {
    let startTimestamp = new Date(new Date().getTime() - (15 * 60000))
    if (reservationsRef.current.find(r =>
      getDateFromTimeStamp(r.StartDay.seconds, r.StartDay.nanoseconds) == start &&
      getDateFromTimeStamp(r.EndDay.seconds, r.EndDay.nanoseconds) == end &&
      r.Activity.id == activityRef.id &&
      r.Zone.id == zoneRef.current.id) == null
      && pendingReservationsRef.current.find(r =>
        r.StartDay.toDate().toDateString() == start.toDateString() &&
        r.EndDay.toDate().toDateString() == end.toDateString() &&
        r.ReservationDay.toDate() >= startTimestamp &&
        r.Activity.id == activityRef.current.id &&
        r.Zone.id == zoneRef.current.id) == null) {

       let code = await getAccessCode(start);

      await addDoc(collection(db, "PendingReservations"), {
        AccessCode: code,
        Activity: doc(db, "Activities", activityRef.current.id),
        EndDay: end,
        Payed: false,
        Price: activityRef.current.Price,
        ReservationDay: new Date(),
        StartDay: start,
        UserMail: sessionStorage.getItem("userMail"),
        Zone: doc(db, "Zones", zoneRef.current.id),
        PendingPayment: true,
      }).then(function (docRef) {
        
        if (docRef) {
          setPendingId(docRef.id);
          sessionStorage.setItem("pendingId", docRef.id);
          props.refreshCalendar();
          setPayment(true);
        }

      });

    } else {
      setPaymentErr(true);

    }

  }

  const getAccessCode = async (start) => {


    // Crear una referencia a la colección

    const targetTimestamp = new Date(start);
    
    const collectionRef = collection(db, "AccessCodes");

    // Fecha de consulta

    // Calcular el inicio y fin del día
    const startOfDay = new Date(targetTimestamp.setHours(0, 0, 0, 0));
    const endOfDay = new Date(targetTimestamp.setHours(23, 59, 59));

    // Crear la consulta
    const q = query(
        collectionRef,
        where("fechaInicio", ">=", startOfDay),
        where("fechaInicio", "<=", endOfDay)
    );

    // Ejecutar la consulta
    const snapshot = await getDocs(q);
    let codigoToReturn = 0;
    // Procesar los resultados
    if (!snapshot.empty) {
        snapshot.forEach(doc => {
          codigoToReturn =  doc.data().codigo;
        });
    } else {
        console.log("No se encontraron documentos para el rango de fechas proporcionado.");
        
    }

    return codigoToReturn;
    
  }
  
  const eventStyleGetter = (event) => {
    var backgroundColor = '#' + event.hexColor;
    var style = {
      backgroundColor: backgroundColor,
      color: 'black',
    };
    return {
      style: style
    };
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className='alertDelete center dialogMinWidth'>¿Desea continuar con su reserva?</DialogTitle>
        <DialogContent>
          <DialogContentText className='centerContentDialog'>
            {activityRef.current.Name} en la zona: {zoneRef.current.Name}
          </DialogContentText>
          <DialogContentText className='centerContentDialog'>
            El dia: {selectedReservation.current ? selectedReservation.current.StartDay.toLocaleDateString() : ""}
          </DialogContentText>
          <DialogContentText className='centerContentDialog'>
            Horas:&#32; {selectedReservation.current ? selectedReservation.current.StartDay.getHours().toString().padStart(2, '0') : ""}:
            {selectedReservation.current ? selectedReservation.current.StartDay.getMinutes().toString().padStart(2, '0') : ""}-
            {selectedReservation.current ? selectedReservation.current.EndDay.getHours().toString().padStart(2, '0') : ""}:
            {selectedReservation.current ? selectedReservation.current.EndDay.getMinutes().toString().padStart(2, '0') : ""}
          </DialogContentText>
          <DialogContentText className='centerContentDialog'>
            Precio: {selectedReservation.current ? selectedReservation.current.Price : ""}€
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="error">
            Salir
          </Button>
          <Button onClick={handleSave} color="primary">
            {activityRef.current.Price == 0 ? (
              <p>Aceptar</p>
            ) : (<p>Pagar</p>)}

          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openReservationInfo} onClose={handleClickRInfoClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className='alertDelete center dialogMinWidth'>Información de la Reserva</DialogTitle>
        <DialogContent>
          <DialogContentText className='centerContentDialog'>
            {activityRef.current.Name} en la zona: {zoneRef.current.Name}
          </DialogContentText>
          <DialogContentText className='centerContentDialog'>
            El dia: {selectedReservation.current ? selectedReservation.current.StartDay.toLocaleDateString() : ""}
          </DialogContentText>
          <DialogContentText className='centerContentDialog'>
            Horas:&#32; {selectedReservation.current ? selectedReservation.current.StartDay.getHours().toString().padStart(2, '0') : ""}:
            {selectedReservation.current ? selectedReservation.current.StartDay.getMinutes().toString().padStart(2, '0') : ""}-
            {selectedReservation.current ? selectedReservation.current.EndDay.getHours().toString().padStart(2, '0') : ""}:
            {selectedReservation.current ? selectedReservation.current.EndDay.getMinutes().toString().padStart(2, '0') : ""}
          </DialogContentText>
          <DialogContentText className='centerContentDialog'>
            Precio: {selectedReservation.current ? selectedReservation.current.Price : ""}€
          </DialogContentText>
          <DialogContentText className='centerContentDialog'>
            Código: {selectedReservation.current ? selectedReservation.current.AccessCode : ""}
          </DialogContentText>
        </DialogContent>

      </Dialog>
      <Calendar
        className='calendarWidth'
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        min={min}
        max={max}
        defaultView={Views.DAY}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectEvent}
        selectable={false}
        events={timeReservations}
        views={["day"]}
        toolbar={false}
        slotPropGetter={slotPropGetter}
        defaultDate={props.selectedDate}
        date={props.selectedDate}
        onNavigate={() => { }}
        eventPropGetter={eventStyleGetter}
      />
      {deleteBtn}
      {updated ?
        <nav onClick={() => setUpdated(false)}><ToastMSG message={"Reserva realizada correctamente"} /></nav>
        : ""}
      {payment ?
        <PaymentPlatformForm pendingReservationID={pendingId} activity={activityRef.current}></PaymentPlatformForm>
        : ""}
      {paymentErr ?
        <nav onClick={() => setPaymentErr(false)}>
          <ToastAlertMSG message={"Ya existe otra reserva proveniente de otro usuario en el hueco."} count={1} />
        </nav>
        : ""
      }

    </div>

  );
}

export default TimeCalendar